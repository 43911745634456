import {Component} from '@angular/core';
import {BreadcrumbComponent} from "@core/layout/components/breadcrumb/breadcrumb.component";

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [
    BreadcrumbComponent
  ],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {
  title: string = '';

  setTitle($event:string ) {
    /* Wrap the title value from breadcrumb component to a Promise to avoid NG0100 error
       Reference: https://angular.dev/errors/NG0100 */
    Promise.resolve().then(() => this.title = $event );
  }
}
