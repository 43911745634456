export const environment = {
    applicationName: "EL PASTOR DEL RICA",
    firebaseConfig: {
        apiKey: "AIzaSyD5jTcEvRbOLwEz-pu3q2dLMKHhRHx9W6w",
        authDomain: "el-pastor-del-rica-dev.firebaseapp.com",
        projectId: "el-pastor-del-rica-dev",
        storageBucket: "el-pastor-del-rica-dev.appspot.com",
        messagingSenderId: "77699821553",
        appId: "1:77699821553:web:bdb980059cba7fdcd19e50",
        measurementId: "G-6Z2C9XWEQ7"
    },
    production: false,

};
