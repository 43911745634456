<div class="flex flex-row flex-nowrap page-header">
    <div class="header--title-container">
        <div class="flex flex-initial flex-column align-content-start">
            <div class="flex align-content-center header__title">
                {{ title }}
            </div>
            <div class="flex align-content-center">
                <app-breadcrumb (onPageChange)="setTitle($event)"></app-breadcrumb>
            </div>
        </div>
    </div>
    <div class="flex align-items-center justify-content-center page-header__actions">
        <ng-content></ng-content>
    </div>
</div>
