<div class="breadcrumbs">
  <div class="flex flex-row flex-initial align-items-center justify-content-center">
    <div class="breadcrumbs__icon flex align-items-center justify-content-center">
      <mat-icon>{{Icons.HOME}}</mat-icon>
    </div>
    <ul class="breadcrumbs__list">
      @for (breadcrumb of breadcrumbs; track breadcrumb.label; let last = $last){
        @if(last){
          <li>{{breadcrumb.label}}</li>
        } @else {
          <li>
            <a routerLink="{{breadcrumb.url}}">{{breadcrumb.label}}</a>
            <span class="breadcrumbs__slash">/</span>
          </li>
        }
      }
    </ul>
  </div>
</div>
