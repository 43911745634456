<mat-toolbar color="secondary">
  <button mat-icon-button (click)="menuToggle()">
    <mat-icon>{{ menuIcon }}</mat-icon>
  </button>
  <span class="toolbar-title">
    <span class="toolbar-title__company-name">{{ applicationName }}<span
      class="toolbar-title__application-name">Shiftster</span></span>
  </span>
  <span class="spacer"></span>
  <button mat-icon-button (click)="accountToggle()">
    <mat-icon>{{ accountIcon }}</mat-icon>
  </button>
</mat-toolbar>
<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav class="menu-sidenav" #menu>
    <mat-nav-list class="sidenav-menu-list">
      <mat-list-item routerLink="/home" (click)="menuToggle()">
        <mat-icon matListItemIcon>{{ Icons.HOME }}</mat-icon>
        <div matListItemTitle class="mdc-list-item__title">Inicio</div>
      </mat-list-item>
      <mat-list-item routerLink="/orders" (click)="menuToggle()">
        <mat-icon matListItemIcon>{{ Icons.ORDERS }}</mat-icon>
        <div matListItemTitle class="mdc-list-item__title">Ordenes</div>
      </mat-list-item>
      <mat-list-item routerLink="/dispatcher" (click)="menuToggle()">
        <mat-icon matListItemIcon>{{ Icons.DISPATCHER }}</mat-icon>
        <div matListItemTitle class="mdc-list-item__title">Despachador</div>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav class="account-sidenav" #account position="end">
    <div class="flex flex-column user">
      <div class="flex align-items-center justify-content-center">
        <div class="user__image p-2">
          <img alt="Account profile" src="/images/avatar.png" height="100" width="100">
        </div>
      </div>
      <div class="flex flex-column align-items-center justify-content-center">
        <div class="user__name">{{ user?.fullName }}</div>
        <div class="user__email">{{ user?.email }}</div>
      </div>
    </div>
    <div class="flex align-items-start">
      <!--<mat-list role="list">
        <mat-list-item role="listitem" class="list-item__link" routerLink="/">Cambiar contraseña
        </mat-list-item>
      </mat-list>-->
    </div>
    <div class="absolute bottom-0 left-0 logout-container" (click)="logout()">
      <div class="logout flex align-items-center justify-content-center">
        <span class="logout__link">Cerrar Sesión</span>
        <mat-icon class="ml-2 logout__icon">logout</mat-icon>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
