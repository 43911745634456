import {Component, OnInit, output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {Breadcrumb} from "@core/layout/components/types/breadcrumb.type";
import {isNull as _isNull, isUndefined as _isUndefined} from 'lodash-es';
import {MatIcon} from "@angular/material/icon";
import {Icons} from "@shared/icons.constants";
import {filter} from "rxjs";

@Component({
    selector: 'app-breadcrumb',
    standalone: true,
    imports: [
        MatIcon,
        RouterLink
    ],
    templateUrl: './breadcrumb.component.html',
    styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent implements OnInit {
    protected readonly Icons = Icons;
    breadcrumbs: Array<Breadcrumb> = [];
    onPageChange = output<string>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.setPageHeaderInformation();

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.setPageHeaderInformation();
            });
    }

    private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<Breadcrumb> = []): Array<Breadcrumb> {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            return breadcrumbs;
        }

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            if (!_isNull(routeURL) || !_isUndefined(routeURL)) {
                url += `/${routeURL}`;
            }

            breadcrumbs.push({
                title: child.snapshot.data['title'],
                label: child.snapshot.data['breadcrumb'],
                url: url
            });
            return this.createBreadcrumbs(child, url, breadcrumbs);
        }

        return breadcrumbs;
    }

    private setPageHeaderInformation() {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root).filter(breadcrumb => !_isUndefined(breadcrumb.label));
        // Send the current page title to page-header component
        this.onPageChange.emit(this.breadcrumbs[this.breadcrumbs.length - 1].title);
    }
}
